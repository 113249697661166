<template lang="pug">
.feedback-stars.d-flex.justify-center.flex-column
  .stars-rating(@mouseleave="hovered = null")
    ui-icon.ui-clickable.stars-rating__item(
      :class="value >= i || (hovered && hovered >= i) ? '-full' : ''"
      v-for="i in +maxStars"
      :key="'s' + i"
      size=32
      :name="value < i ? 'star-outline' : 'star-full'"
      @click="$emit('input', i)"
      @mouseover="hovered = i"
    )
  .d-flex.justify-space-between.t-small.c-gray70.mt-2
    span Плохо
    span Отлично
</template>

<script>
  import { UiIcon } from '@/uikit/';

  export default {
    name: 'feedback-stars',
    components: { UiIcon },
    props: {
      value: { type: Number, default: 0 },
      maxStars: { type: [Number, String], default: 0 },
      color: { type: String, default: 'rgba(255,255,255, 0.6)' },
      colorBright: { type: String, default: '#75BD7A' },
    },
    data() {
      return {
        hovered: null,
      };
    },
  };
</script>

<style lang="scss">
  .feedback-stars {
    width: 100%;
  }

  .stars-rating {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .stars-rating__item {
      color: rgba(white, 0.6);
    }

    .stars-rating__item.-full {
      color: c('primary40', 'light');
    }
  }
</style>
