<template lang="pug">
.feedback-form(:class="classes")
  .mx-10(v-if="currentStep === 1")
    h3.t-h3-mobile.text-center.c-white {{ textRate }}
    //- звезды
    feedback-stars(v-model="rating" :max-stars="starsMax" @input="addComment")

  v-expand-transition(:duration="{ leave: 0 }")
    .feedback-form__comment(v-if="currentStep === 1 && showComment")
      .t-normal-bold.mt-8 Комментарий
      v-textarea.feedback-form__textarea(
        ref="comment"
        v-model="comment"
        autocomplete="off"
        dark
        :counter="maxCommentLength"
        :rules="commentRules"
        @keyup.enter.ctrl="send"
      )

      v-btn.mt-6.border-primary--light(
        color="primary"
        dark
        outlined
        width="100%"
        :height="44"
        @click="send"
        :loading="loading"
        :disabled="(comment || '').length > maxCommentLength"
      ) Отправить

  .feedback-state(v-if="currentStep === 2 || currentStep === 3")
    .t-h3-mobile Спасибо за Вашу оценку!
    .t-normal.mt-1 Вы помогаете нам делать Portal DA лучше

  //- .feedback-state(v-if="currentStep === 3")
  //-   .t-h3-mobile Ошибка
  //-   .t-normal.mt-1 {{ errorText }}

  .feedback-form__close-btn(@click="close")
    ui-icon(name="close")
</template>

<script>
  import { SimpleApi as API } from '@/libs/api/lib/simpleApi';
  import { UiIcon } from '@/uikit/';
  import FeedbackStars from './FeedbackStars.vue';

  export default {
    name: 'feedback-form',

    components: {
      FeedbackStars,
      UiIcon,
    },

    props: {
      value: { type: Boolean, default: false }, // видимость
      action: { type: String, default: '' },
      rateText: { type: String, default: '' },
      maxStars: { type: Number, default: 5 },
      maxCommentLength: { type: Number, default: 250 },
    },

    data() {
      return {
        // опции
        actionType: this.action, // поле для API, это действие пользователя
        textRate: this.rateText,
        starsMax: this.maxStars, // всего звезд
        commentLengthMax: this.maxCommentLength,
        // модель
        satisfied: null, // кнопка Да/Нет true/false или null
        rating: 0, // рейтинг 1-10 или 0
        comment: '',
        errorText: '',
        // состояния
        showComment: false,
        currentStep: 1,
        loading: false,
      };
    },

    computed: {
      classes() {
        return {
          'feedback-form--success': this.currentStep === 2 || this.currentStep === 3,
          // 'feedback-form--failed': this.currentStep === 3,
        };
      },

      commentRules() {
        return [
          (v) =>
            (v || '').length <= this.maxCommentLength ||
            `Не более ${this.maxCommentLength} символов`,
        ];
      },
    },

    created() {
      this.reset();
    },

    methods: {
      async send() {
        if (this.loading || this.comment.length > this.maxCommentLength) return false;
        this.loading = true;
        const response = await API.post('/api/v2/csi/rating.json', {
          evaluation: {
            action: this.actionType,
            satisfied: this.satisfied, // кнопка Да/Нет true/false или null
            rating: this.rating, // рейтинг 1-10 или 0
            comment: this.comment,
          },
        });
        this.loading = false;
        if (response.ok) {
          this.currentStep = 2;
        } else {
          this.currentStep = 3;
          this.errorText = response.error;
        }

        const timer = setTimeout(() => {
          this.close();
          clearTimeout(timer);
        }, 4000);
      },

      addComment() {
        this.showComment = true;
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.comment);
          this.$refs.comment.$refs.input.focus();
        });
      },

      reset() {
        // сбрасываем на начальное состояние
        this.showForm = false;
        this.showComment = false;
        this.currentStep = 1;
        this.satisfied = null;
        this.rating = 0;
      },

      close() {
        this.$emit('click:close');
        this.reset();
      },
    },
  };
</script>

<style lang="scss">
  // possible global
  .border-primary--light {
    border-color: c('primary40', 'light');
  }

  .feedback-form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 340px;
    min-height: 170px;
    padding: 40px 16px;
    border-radius: 8px;
    background: c('gray100', 'light');

    &.feedback-form--success {
      background: c('primary60', 'light');
    }

    &.feedback-form--failed {
      background: c('red60', 'light');
    }

    .t-normal-bold,
    .v-btn {
      color: white;
    }

    .v-btn--outlined.theme--dark:not(.v-btn--disabled) {
      transition: color 0.3s ease-in-out;
      border-color: c('primary40', 'light');
      color: white !important;
      font-size: 16px;

      &:hover {
        border-color: c('primary40', 'light');
        color: c('primary60', 'light');
      }
    }

    .feedback-stars {
      width: 100%;
    }

    .feedback-state {
      text-align: center;
      color: c('white', 'light');
    }
  }

  .feedback-form__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    transition: color 0.2s ease-in-out;
    color: rgba(white, 0.6);
    cursor: pointer;
  }

  .feedback-form__comment {
    width: 100%;
  }

  .feedback-form__textarea {
    &.v-text-field {
      margin-top: 0;
      padding-top: 4px;
    }

    &.v-input {
      color: c('gray40', 'light');
      font-size: 15px;
    }

    textarea {
      color: c('gray40', 'light') !important;
      max-height: 180px;
      background: repeating-linear-gradient(
        transparent,
        transparent 19px,
        c('gray80', 'light') 19px,
        c('gray80', 'light') 20px
      );
      font-size: inherit;
      line-height: 20px !important;
      overflow: hidden;
    }
  }
</style>
